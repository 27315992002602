<template>
    <h2>
     Coming Soon! For now visit our <a href="https://www.etsy.com/shop/HarkandHeed?ref=shop_sugg_market">Etsy Store</a>.
    </h2>

    <h3><a :href="`${publicPath}2025_herbs.pdf`" download="2025_herbs">2025 Herb List</a></h3>
  </template>
  
  <script>
  // @ is an alias to /src
  
  export default {
    name: 'ShopView',
    components: {
    },
    data () {
      return {
        publicPath: process.env.BASE_URL
      }
    },
  }
  </script>
  